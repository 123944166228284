<mat-drawer-container class="main-drawer-container" *ngIf="!hideNav">
  <mat-drawer
    *ngIf="!isAuthorizing"
    class="main-menu-container"
    #sidenav
    mode="side"
    [(opened)]="sideNavOpened"
  >
    <app-main-menu (closeSideNav)="sideNavOpened = false"></app-main-menu>
  </mat-drawer>
  <mat-drawer-content class="main-container">
    <app-toolbar
      *ngIf="!isAuthorizing"
      [isSideNavOpend]="sideNavOpened"
      (toggleSideNav)="sideNavOpened = !sideNavOpened"
    ></app-toolbar>
    <app-loader *ngIf="isAuthorizing"></app-loader>
    <router-outlet *ngIf="!isAuthorizing"></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>
<div *ngIf="hideNav">
  <router-outlet></router-outlet>
</div>
