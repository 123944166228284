import { UserWithRoles } from "src/app/models/user-access";

export class GetUserWithRoles {
    static readonly type = '[UserRoles] Get';
    constructor() { }
}

export class UpdateUserRoles {
    static readonly type = '[UserRoles] Update';
    constructor(public payload: UserWithRoles) { }
}