import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { PointSystemItem } from "../models/point-system-item";

@Injectable({
    providedIn: 'root'
})
export class PointSystemService {
    private apiUrl = `${environment.api.url}PointSystem`;

    constructor(private http: HttpClient) { }

    getPointSystemItems(): Observable<PointSystemItem[]> {
        return this.http.get<PointSystemItem[]>(`${this.apiUrl}/GetPointSystemItems`);
    }

    getPointSystemItem(id: number): Observable<PointSystemItem> {
        return this.http.get<PointSystemItem>(`${this.apiUrl}/GetPointAction/${id}`);
    }

    updatePointSystemItem(pointSystemItem: PointSystemItem): Observable<void> {
        return this.http.put<void>(`${this.apiUrl}/PutPointAction/${pointSystemItem.id}/${pointSystemItem.points}`, {});
    }
}