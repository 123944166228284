import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { catchError, concatMap, Observable, throwError, from } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { Router } from "@angular/router";
import { environment } from "../../../environments/environment";
import { oAuthServiceConfig } from "../auth.config";

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  private oAuthService: OAuthService;
  private router: Router;


  constructor(private injector: Injector) {
    this.oAuthService = this.injector.get(OAuthService);
    this.router = this.injector.get(Router);

  }

  private async tryLoadDiscoveryDocument(): Promise<void> {
    if (!this.oAuthService.discoveryDocumentLoaded) {
      this.oAuthService.configure(oAuthServiceConfig);
      await this.oAuthService.loadDiscoveryDocument();
    }
  }

  private retryRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const updatedRequest = req.clone({
      setHeaders: {
        Authorization: `Bearer ${this.oAuthService.getAccessToken()}`,
      },
    });
    return next.handle(updatedRequest);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.startsWith(environment.auth.identity.issuer.toLowerCase())) {
      req = req.clone({
        withCredentials: true,
      });
    }
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse): Observable<HttpEvent<any>> => {
        if (error.status === 401) {
          return from(this.tryLoadDiscoveryDocument()).pipe(
            concatMap(() => {
              return from(this.oAuthService.refreshToken()).pipe(
                concatMap(() => this.retryRequest(req, next)),
                catchError((err) => {
                  localStorage.setItem('requested_route', this.router.url);
                  this.oAuthService.initLoginFlow(undefined, { scheme: 'Microsoft' });
                  return throwError(() => new Error('Token refresh failed'));
                })
              );
            })
          );
        }
        return throwError(() => error);
      })
    );
  }
}
