import {AuthConfig} from 'angular-oauth2-oidc';
import {environment} from 'src/environments/environment';

export const oAuthServiceConfig: AuthConfig = {
  clientId: environment.auth.identity.clientId,
  issuer: environment.auth.identity.issuer,
  redirectUri: window.location.origin ,
  responseType: 'code',
  scope: environment.auth.scope,
  showDebugInformation: true,
  useSilentRefresh: true,
  oidc: false,
  clearHashAfterLogin: true
};
