import { DOCUMENT } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { inject, Inject, Injectable, NgZone } from '@angular/core';
import { Store } from "@ngxs/store";
import {
  DateTimeProvider, HashHandler,
  OAuthEvent,
  OAuthLogger,
  OAuthService, OAuthStorage,
  UrlHelperService,
  ValidationHandler
} from 'angular-oauth2-oidc';
import { BehaviorSubject, Observable, switchMap } from "rxjs";
import { Role, UserWithRoles } from "src/app/models/user-access";
import { environment } from 'src/environments/environment';
import { MainRoutesEnum } from "../../enums/main-routes.enum";
import { oAuthServiceConfig } from "../auth.config";

const issuer = environment.auth.identity.issuer;

@Injectable({
  providedIn: 'root'
})
export class AuthService extends OAuthService {
  store = inject(Store);
  user$ = new BehaviorSubject<any | null>(null);

  constructor(
    ngZone: NgZone,
    http: HttpClient,
    storage: OAuthStorage,
    tokenValidationHandler: ValidationHandler,
    urlHelper: UrlHelperService,
    logger: OAuthLogger,
    crypto: HashHandler,
    @Inject(DOCUMENT) document: any,
    dateTimeService: DateTimeProvider,
  ) {
    super(ngZone, http, storage, tokenValidationHandler, oAuthServiceConfig, urlHelper, logger, crypto, document, dateTimeService);
    this.configure(oAuthServiceConfig);
    this.loadDiscoveryDocumentAndTryLogin().then();
    this.events.subscribe((event: OAuthEvent) => {
      if (event.type === 'token_received') {
        this.setupAutomaticSilentRefresh();
        this.loadUser().then();
      }
    });
  }


  async loadUser() {
    const authUser = this.getIdentityClaims();
    if (!authUser) {
      return await this.reloadUser();
    } else {
      this.user$.next(authUser);

      return authUser;
    }
  }

  async reloadUser() {
    await this.checkLoadDiscoveryDocument();
    const userProfileObject = await this.loadUserProfile();
    const authUser = (userProfileObject as any).info;
    this.user$.next(authUser);

    return authUser;
  }


  async loginMicrosoft(): Promise<void> {
    await this.checkLoadDiscoveryDocument();
    this.initLoginFlow(undefined, { scheme: 'Microsoft' });
  }

  isAuthenticated(): boolean {
    return this.hasValidAccessToken();
  }


  async tryRefreshToken() {
    if (
      !this.hasValidAccessToken() &&
      this.getRefreshToken()
    ) {
      await this.checkLoadDiscoveryDocument();
      await this.refreshToken();
    }
  }

  checkIfCodeAvailiable(querySource: string) {
    const parts = this.getCodeParts(querySource) as { code: string, state: string };

    const code = parts['code'];
    const state = parts['state'];
    return code != null && state != null;
  }

  private getCodeParts(queryString: string): object {
    if (!queryString || queryString.length === 0) {
      return this.urlHelper.getHashFragmentParams();
    }

    // normalize query string
    if (queryString.charAt(0) === '?') {
      queryString = queryString.substr(1);
    }

    return this.urlHelper.parseQueryString(queryString);
  }

  tryGetSessionState(): string | null {
    return this._storage.getItem('session_state');
  }

  async checkLoadDiscoveryDocument() {
    if (!this.discoveryDocumentLoaded) {
      await this.loadDiscoveryDocument();
    }
  }

  async codeFlowLoginPromise(): Promise<boolean> {
    await this.checkLoadDiscoveryDocument();

    return new Promise<boolean>((resolve, reject) => {
      this.events.subscribe((event: OAuthEvent) => {
        if (event.type === 'token_received') resolve(true);
      });

      this.events.subscribe((event: OAuthEvent) => {
        if (event.type === 'code_error') resolve(false);
      });

      this.tryLoginCodeFlow().catch(err => {
        resolve(false);
        throw new Error(err);
      });
    });
  }

  get requestedUrl() {
    let requestedUrl = localStorage.getItem('requested_route');
    if (!requestedUrl || requestedUrl === '/' || requestedUrl === '/login') requestedUrl = '/' + MainRoutesEnum.landingPage;
    localStorage.removeItem('requested_route');
    return requestedUrl;
  }

  getRoles(): Observable<Array<Role>> {
    return this.http.get<Array<Role>>(`${issuer}/api/Role/all-roles`)
  }

  getUsersWithRoles(): Observable<UserWithRoles[]> {
    return this.http.get<UserWithRoles[]>(`${issuer}/api/Role/microsoft-users-with-roles`);
  }

  updateUserRoles(user: UserWithRoles) {
    return this.http.put(`${issuer}/api/Role/update-user-roles`, user).pipe(
      switchMap(() => this.reloadUser())
    );
  }

  getUserRoles(): Observable<string[]> {
    return this.http.get<string[]>(`${issuer}/api/Account/user-roles`);
  }
}
