<mat-toolbar class="main-toolbar">
  <div class="menu-icon" (click)="onToggleSideNavClick()">
    <mat-icon *ngIf="!isSideNavOpend">menu</mat-icon>
    <mat-icon *ngIf="isSideNavOpend">close</mat-icon>
  </div>
  <h3 class="page-header">{{ this.title.replaceAll("-", "&nbsp;") }}</h3>
  <div>
    <mat-icon>settings</mat-icon>
  </div>
</mat-toolbar>
