import { PointSystemItem } from "src/app/models/point-system-item";

export class GetPointSystemItems {
    static readonly type = '[PointSystemItems] Get';
    constructor() { }
}

export class UpdatePointSystemItem {
    static readonly type = '[PointSystemItem] Update';
    constructor(public payload: PointSystemItem) { }
}