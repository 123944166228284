import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { of, tap } from "rxjs";
import { UserLevel } from "src/app/models/user-level";
import { UserLevelService } from "src/app/services/user-level.service";
import { GetUserLevels, UpdateUserLevel } from "./user-level.action";

type UserLevelStateModel = UserLevel[];

@State<UserLevelStateModel>({
    name: 'UserLevelState',
    defaults: [],
})
@Injectable()
export class UserLevelState {

    constructor(private userLevel: UserLevelService) { }

    @Selector()
    static getUserLevels(state: UserLevelStateModel) {
        return state;
    }

    @Action(GetUserLevels)
    fetchAlbumTypes(ctx: StateContext<UserLevelStateModel>) {

        if (ctx.getState().length) {
            return of(ctx.getState());
        }

        return this.userLevel.getUserLevels().pipe(tap(items => ctx.setState(items)));
    }

    @Action(UpdateUserLevel)
    updateUserRoles(ctx: StateContext<UserLevelStateModel>, action: UpdateUserLevel) {
        const state = ctx.getState();
        const newitem = action.payload;

        this.userLevel.updateUserLevel(newitem).subscribe(() => {
            return state.map(item => {
                if (item.id == newitem.id) {
                    return newitem
                }
                return item;
            })
        });
    }

}