import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { UserLevel, UserLevelsLog } from "../models/user-level";

@Injectable({
    providedIn: 'root'
})
export class UserLevelService {
    private apiUrl = `${environment.api.url}UserLevels`;

    constructor(private http: HttpClient) { }

    getUserLevels(): Observable<UserLevel[]> {
        return this.http.get<UserLevel[]>(`${this.apiUrl}/GetUserLevels`);
    }

    updateUserLevel(userLevel: UserLevel): Observable<void> {
        return this.http.put<void>(`${this.apiUrl}/UpdateUserLevel/${userLevel.id}/${userLevel.pointsRequired}`, {});
    }

    updateUserLevels(): Observable<void> {
        return this.http.post<void>(`${this.apiUrl}/UpdateUserLevels`, {});
    }

    getUserLevelsLog(page: number,
        pageSize: number,
        sortBy: string,
        sortDirection: string,
        fromDate: Date | null,
        toDate: Date | null
    ): Observable<any> {
        let params = new HttpParams()
            .set('page', page.toString())
            .set('pageSize', pageSize.toString())
            .set('sortBy', sortBy)
            .set('isSortAscending', sortDirection === 'asc' ? true : false)
            .set('fromDate', fromDate?.toDateString() ? fromDate?.toDateString() : '')
            .set('toDate', toDate?.toDateString() ? toDate?.toDateString() : '');

        return this.http.get<UserLevelsLog[]>(`${this.apiUrl}/GetUserLevelsLogs`, { params });
    }
}