import { CommonModule, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { Router, RouterOutlet } from '@angular/router';
import { OAuthEvent } from "angular-oauth2-oidc";
import { AuthService } from "../../auth/services/auth.service";
import { LoaderComponent } from "../loader/loader.component";
import { MainMenuComponent } from '../main-menu/main-menu.component';
import { ToolbarComponent } from '../toolbar/toolbar.component';

@Component({
  selector: 'app-main',
  standalone: true,
  imports: [CommonModule, ToolbarComponent, MatSidenavModule, MainMenuComponent, RouterOutlet, LoaderComponent, NgIf],
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent {
  isAuthorizing = false;
  sideNavOpened: boolean = true;
  hideNav = false;

  constructor(private authService: AuthService, private router: Router) {
    if (window.location.href.includes("Screenshots")) {
      this.hideNav = true
    }
    else {
      const url = router.url.split('?')[0];
      const isAuthorizing = !authService.isAuthenticated() && url === '/' || url === '/login';
      if (isAuthorizing) {
        authService.events.subscribe((event: OAuthEvent) => {
          if (event.type === 'token_received') {
            this.isAuthorizing = false;
          }
        });
      }
      this.isAuthorizing = isAuthorizing;
    }
    if (window.innerWidth < 1024) {
      this.sideNavOpened = false
    }
  }
}
