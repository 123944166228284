import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { of, tap } from "rxjs";
import { PointSystemItem } from "src/app/models/point-system-item";
import { PointSystemService } from "src/app/services/point-system.service";
import { GetPointSystemItems, UpdatePointSystemItem } from "./point-system.action";

type PointSystemStateModel = PointSystemItem[];

@State<PointSystemStateModel>({
    name: 'PointSystemState',
    defaults: [],
})
@Injectable()
export class PointSystemState {

    constructor(private pointSystem: PointSystemService) { }

    @Selector()
    static getPointSystemItems(state: PointSystemStateModel) {
        return state;
    }

    @Action(GetPointSystemItems)
    fetchAlbumTypes(ctx: StateContext<PointSystemStateModel>) {

        if (ctx.getState().length) {
            return of(ctx.getState());
        }

        return this.pointSystem.getPointSystemItems().pipe(tap(items => ctx.setState(items)));
    }

    @Action(UpdatePointSystemItem)
    updateUserRoles(ctx: StateContext<PointSystemStateModel>, action: UpdatePointSystemItem) {
        const state = ctx.getState();
        const newitem = action.payload;

        this.pointSystem.updatePointSystemItem(newitem).subscribe(() => {
            return state.map(item => {
                if (item.id == newitem.id) {
                    return newitem
                }
                return item;
            })
        });
    }

}