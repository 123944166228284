
import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { map } from "rxjs";
import { AuthService } from "./services/auth.service";

export function roleGuardFactory(requiredRoles: string[]): CanActivateFn {
    return (route, state) => {
        const router = inject(Router);
        const authService = inject(AuthService);

        return authService.user$.pipe(
            map(user => {
                if (user) {

                    const roles: string[] = user['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] || [];
                    const hasRole = requiredRoles.some(role => roles.includes(role));

                    if (!hasRole) {
                        // Redirect to a forbidden page or any other route
                        return router.createUrlTree(['/forbidden']);
                    }

                    return true;
                }
                return false;
            })
        );
    };
}
