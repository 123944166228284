
export class KeyValuePair {
    Key: number;
    Value: any;

    constructor(Key: number, Value?: any) {
        this.Key = Key;
        this.Value = Value ? Value : '';
    }
}
