<app-main-menu-toolbar></app-main-menu-toolbar>
<div>
  <mat-nav-list *ngIf="user$ |async">
    <a
      *ngFor="let item of filteredMenuList"
      mat-list-item
      [routerLink]="item.route"
      [activated]="item.route === selectedPage?.route"
      (click)="closeNav()"
    >
      <mat-icon
        matListItemIcon
      >{{ item.icon }}</mat-icon
      >
      <span matListItemTitle class="mp-navigation-item-text">{{
        item.label
        }}</span>
    </a>
  </mat-nav-list>
</div>
