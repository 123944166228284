import { entLanguage, LocalizationLabelDto } from "src/app/models/localization/localization-label-dto";

export class GetAllLocalizationDto {
    static readonly type = '[LocalizationLabelDto] Get';
    constructor() { }
}

export class UpdateLabelValue {
    static readonly type = '[LocalizationLabelDto] Update';
    constructor(public payload: LocalizationLabelDto) { }
}

export class GenerateAllLabelsWithMissingTranslations {
    static readonly type = '[Localization] Generate All Labels With Missing Translations';
    constructor(public payload: { labels: LocalizationLabelDto[], languages: entLanguage[] }) { }
}

export class AddLanguageApproval {
    static readonly type = '[Localization] Add Language Approval';
    constructor(public languagePkey: number, public typeKey: number, public user: any) { }
}

export class DeleteLanguage {
    static readonly type = '[Localization] Delete Language';
    constructor(public languagePKey: number) { }
}

export class AddLanguage {
    static readonly type = '[Localization] Add Language';
    constructor(public params: { code: string, name: string }) { }
}

export class UpdateLocalization {
    static readonly type = '[Localization] Update';
    constructor() { }
}

export class UpdateLocalizationEntityScreenshots {
    static readonly type = '[Localization] Update Localization Entity Screenshots';
    constructor(public entityPKey: number, public screenshotsToAdd: string[], public shouldDelete: boolean, public screenshotPkey: number | undefined) { }
}