import {inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import {GoogleAnalyticsReques} from "../../models/google-analytics/google-analytics-reques";
import {IRunReportResponse} from "../../models/google-analytics/google-protos";

@Injectable({ providedIn: 'root' })
export class GoogleAnalyticsService {
  private http = inject(HttpClient);

  private readonly url: string = `${environment.api.url}GoogleAnalyticsData`;

  public getGoogleAnalyticsData(payload: GoogleAnalyticsReques): Observable<IRunReportResponse> {
    return this.http.post<IRunReportResponse>(this.url + '/GetGoogleAnalyticsData', payload );
  }
}
