import {inject, Injectable} from '@angular/core';
import {  Router, CanActivateFn, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {AuthService} from "./services/auth.service";

@Injectable({
  providedIn: 'root'
})
class AuthGuardService {

  constructor(private router: Router, private authService: AuthService) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

    const refreshTokenPassedSuccessfully = await this.authService.tryRefreshToken()
      .then(() => true)
      .catch(() => false);


    if (this.authService.isAuthenticated() && refreshTokenPassedSuccessfully) {
      return true;
    }
    localStorage.setItem('requested_route', state.url);
    await this.authService.loginMicrosoft();

    return false;
  }
}

export const AuthGuard: CanActivateFn = async (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> => {
  return inject(AuthGuardService).canActivate(next, state);
}


