import { UserLevel } from "src/app/models/user-level";

export class GetUserLevels {
    static readonly type = '[UserLevels] Get';
    constructor() { }
}

export class UpdateUserLevel {
    static readonly type = '[UserLevel] Update';
    constructor(public payload: UserLevel) { }
}