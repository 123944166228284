import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { KeyValuePair } from '../models/KeyValuePair';
import { entLanguage, entLanguageApproval, LocalizationEntity, LocalizationLabelDto } from '../models/localization/localization-label-dto';
import { localizationGroup } from '../models/localization/localizationGroup';
import { localizationItem } from '../models/localization/localizationItem';

@Injectable({ providedIn: 'root' })
export class LocalizationService {
  public path: string = environment.api.url + 'Localization';

  constructor(
    private http: HttpClient,
    private toastrService: ToastrService
  ) {
  }

  public getLocalizationEntitieswithScreenshots(
  ): Observable<LocalizationEntity[]> {
    return this.http.get<LocalizationEntity[]>(`${environment.api.url}Localization/GetLocalizationEntities`)
  }

  public getSingleLocalizationEntity(entPkey: number): Observable<LocalizationEntity> {
    return this.http.get<LocalizationEntity>(`${this.path}/GetLocalizationEntity/${entPkey}`)
  }

  public deleteLanguage(languagePKey: number): Observable<void> {
    return this.http.delete<void>(environment.api.url + 'localization/deleteLanguage?languagePKey=' + languagePKey);
  }

  public AddLanguageApproval(languagePkey: number, typeKey: number, userId: string): Observable<entLanguageApproval> {
    const headers = new HttpHeaders({});
    return this.http.post<entLanguageApproval>(
      `${environment.api.url}localization/AddLanguageApproval/${languagePkey}/${typeKey}/${userId}`,
      null,
      { headers }
    );
  }

  public getAllLanguageCodes(): Observable<entLanguage[]> {
    return this.http.get<entLanguage[]>(`${environment.api.url}localization/GetLanguages`)
  }

  public getEntitiesSummary(TypePkey: number): Observable<any> {
    return this.http.get<any[]>(`${environment.api.url}localization/GetSummary/${TypePkey}`)
  }

  public getAllLabelsAndValues(): Observable<LocalizationLabelDto[]> {
    const headers = new HttpHeaders({ "X-API-Key": environment.auth.KPIHeaderKey });
    return this.http.get<LocalizationLabelDto[]>(`${environment.api.url}localization/GetAllLabelsAndValues`, { headers })
  }

  updateLabelValue(labelDto: LocalizationLabelDto): Observable<void> {
    return this.http.put<void>(`${environment.api.url}localization/UpdateLabelValue`, labelDto);
  }


  public getCodes(): Observable<any[]> {
    return this.http.get<any[]>(`${this.path}/languageCodes`);
  }

  public addLanguage(params: { code: string, name: string }): Observable<any> {
    return this.http.post<any>(`${this.path}/AddLanguage`, params);
  }

  public exportDocument(entityType: number) {
    const headers = new HttpHeaders({});

    this.http.get(this.path + '/Export' + '?localizationType=' + entityType, {
      headers: headers,
      responseType: 'blob',
      observe: 'response',
    }).subscribe({
      next: (response) => {
        const dataType = response.body?.type;
        const xFilename = response.headers.get('x-filename');

        if (response.body && dataType && xFilename) {
          const binaryData: BlobPart[] = [response.body];
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
          downloadLink.setAttribute('download', xFilename);
          downloadLink.target = '_blank';
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        } else {
          console.error('Failed to get valid data from response');
        }
      },
      error: (error) => {
        console.error('Error exporting document:', error);
      },
      complete: () => {
        console.log('Document export completed');
      }
    });
  }

  exportLocalizationPage(data: any) {
    const headers = new HttpHeaders({
    });

    const params = {
      lngPKey: data.lang,
      entPKey: data.pkey,
      entityTypePKey: data.entityTypePKey
    };

    this.http.get(`${this.path}/ExportLocalizationPage/`, { headers, params, responseType: 'blob', observe: 'response' })
      .subscribe(response => {
        const dataType = response.body!.type;
        const xFilename = response.headers.get('x-filename');
        const binaryData: BlobPart[] = [response.body!];
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
        downloadLink.setAttribute('download', xFilename!);
        downloadLink.target = '_blank';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      });
  }

  public importDocument(data: any) {
    const headers = new HttpHeaders({});

    const formData = new FormData();
    formData.append('File', data.file, data.file.name);
    formData.append('entPKey', data.entPKey);
    formData.append('lngPKey', data.lang);

    return this.http.post<any>(`${this.path}/GetImportData`, formData, { headers });
  }

  public importApply(object: any) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json;charset=UTF-8');
    return this.http.post<any>(`${this.path}/ApplyImport`, object, { headers });
  }

  protected handleErrors<T>(promise: Promise<T>): Promise<T> {
    return promise.catch((err) => {
      //errors
      console.log(err);
      this.toastrService.error(err);
      return null as T;
    });
  }

  public castEnumToCollection(param: any): KeyValuePair[] {
    const collection: KeyValuePair[] = [];

    for (const value in param) {
      if (!isNaN(Number(value))) {
        collection.push(new KeyValuePair(Number(value)));
      } else {
        collection.find((e) => e.Value === '')!.Value = value;
      }
    }

    return collection;
  }

  public flattenJSONWithGroups(
    obj: any,
    parentKey = ''
  ): (localizationItem | localizationGroup)[] {
    let flattenedList: (localizationItem | localizationGroup)[] = [];

    for (const key of Object.keys(obj)) {
      const value = obj[key];
      const combinedKey = parentKey ? `${key}` : key;

      if (typeof value === 'object' && value !== null) {
        const groups = this.flattenJSON(value, '');
        flattenedList.push({
          key: combinedKey,
          value: groups as localizationItem[],
        });
      }
    }
    return flattenedList;
  }

  flattenJSON(obj: any, parentKey = ''): localizationItem[] {
    let flattenedList: localizationItem[] = [];

    for (const key of Object.keys(obj)) {
      const value = obj[key];
      const combinedKey = parentKey ? `${parentKey}.${key}` : key;

      if (typeof value === 'object' && value !== null) {
        const items = this.flattenJSON(value, combinedKey);
        flattenedList = flattenedList.concat(items);
      } else {
        flattenedList.push({
          key: combinedKey,
          value: value,
        });
      }
    }

    return flattenedList;
  }

  public AddLocalization(
    localization: (localizationItem | localizationGroup)[],
    localizationType: number
  ) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.http.post<any>(
      environment.api.url + 'localization/AddLocalization',
      { localization, localizationType },
      httpOptions
    );
  }


  public updateScreenshots(
    entityPKey: number,
    screenshotsToAdd: string[],
    shouldDelete: boolean,
    screenshotPkey?: number,
  ) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    const addScreenshots$ = this.http.post<any>(
      environment.api.url + 'localization/AddLocalizationEntityScreenShot?entityPKey=' + entityPKey,
      screenshotsToAdd,
      httpOptions
    );

    const deleteScreenshots$ = this.http.delete<any>(
      environment.api.url + 'localization/DeleteLocalizationEntityScreenShot?entityPKey=' + screenshotPkey
    );

    return shouldDelete ? deleteScreenshots$ : addScreenshots$;
  }
}
