import {GoogleAnalyticsReques} from "../../models/google-analytics/google-analytics-reques";
import {inject, Injectable} from "@angular/core";
import {Action, Selector, State, StateContext, Store} from "@ngxs/store";
import {ExpirableValue} from "../../models/expirable";
import {GetAnalytics} from "./google-analytics.action";
import {append, iif, patch, updateItem} from "@ngxs/store/operators";
import {GoogleAnalyticsService} from "../../services/http-services/google-analytics.service";
import {tap} from "rxjs";
import {IRunReportResponse} from "../../models/google-analytics/google-protos";

export type GoogleAnalyticsResponseExtended = IRunReportResponse & { requestValue: GoogleAnalyticsReques };
export interface GoogleAnalyticsStateModel {
  googleAnalytics:ExpirableValue<GoogleAnalyticsResponseExtended>[];
}

const makeResponseExtended = (response: IRunReportResponse, requestValue: GoogleAnalyticsReques): GoogleAnalyticsResponseExtended => ({
  ...response,
  requestValue
});

const makeExpirableValue = (response: IRunReportResponse, request: GoogleAnalyticsReques): ExpirableValue<GoogleAnalyticsResponseExtended> => ({
  value: makeResponseExtended(response, request),
  timestamp: new Date()
});

@State<GoogleAnalyticsStateModel>({
  name: 'GoogleAnalyticsState',
  defaults: {
    googleAnalytics: [],
  },
})
@Injectable()
export class GoogleAnalyticsState {
  private _httpGoogleAnalyticsService = inject(GoogleAnalyticsService);

  @Selector()
  static selectGoogleAnalytics(state: GoogleAnalyticsStateModel) {
    return (payload: GoogleAnalyticsReques) => {
          return state.googleAnalytics.find(i => JSON.stringify(i.value.requestValue) === JSON.stringify(payload));
      };
  }

  @Action(GetAnalytics)
  getAnalytics(ctx: StateContext<GoogleAnalyticsStateModel>, action: GetAnalytics) {
    const googleAnalyticsState =  ctx.getState().googleAnalytics;
    const googleAnalyticsResponse = googleAnalyticsState.find(i => JSON.stringify(i.value.requestValue) === JSON.stringify(action.payload));
    if(googleAnalyticsResponse?.timestamp && (new Date().getTime() - googleAnalyticsResponse.timestamp.getTime()) < 1000 * 60 * 5)
      return;
    return this._httpGoogleAnalyticsService.getGoogleAnalyticsData(action.payload).pipe(tap(
      (response: IRunReportResponse) => {
        const expirableValue: ExpirableValue<GoogleAnalyticsResponseExtended> = makeExpirableValue(response, action.payload);
        ctx.setState(patch({
          googleAnalytics: iif(googleAnalyticsState.some(i => JSON.stringify(i.value.requestValue) === JSON.stringify(action.payload)),
            updateItem(i => JSON.stringify(i.value.requestValue) === JSON.stringify(action.payload), expirableValue),
            append([expirableValue]))
        }));
      },
    ));
  }
}
